.unit-description-v1 {
	.description {
		margin-bottom: 30px;
	}

	.show-more {
		font-size: var(--font-size-md);
	}

	/*	p {
		word-break: break-all;
	} */
}